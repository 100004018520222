const uslug = require('uslug');

function getArticlePath(article) {
  if (article.prebuilt_url?.indexOf('/article/') === 0) {
    return article.prebuilt_url;
  }

  return '/article/' + uslug(article.title || '') + `_${article.id}`;
}

module.exports = {
  getArticlePath,
};
